const config = {
  apiGateway: {
    REGION: 'eu-west-1',
    INTERNAL_API_URL: 'https://mf2wyk3pyj.execute-api.eu-west-1.amazonaws.com/prod',
    TEAM_API_URL: 'https://82fmifxi24.execute-api.eu-west-1.amazonaws.com/api'
  },
  reactApp: {
    VERSION: 'hotfix/CV4-2316-hotfix-all-company-structure-to-be-shown-device-config',
    HOSTNAME: 'https://teams-service.testing.macquarie.forwoodsafety.com'
  },
  configurationService: {
    URL: 'https://config.testing.macquarie.forwoodsafety.com',
    WEBSOCKET: 'wss://hnjnmnawa0.execute-api.eu-west-1.amazonaws.com/testing'
  },
};

export default config;
